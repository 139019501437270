.anchor{
    position: fixed;
    width: 64px;
    height: 64px;
    background: #22222290;
    border-radius: 32px;
    bottom: 16px;
    right: 28px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    padding-left: 9px;
    padding-top: 24px;
}

@media (max-width:555px){
    .anchor{
        width: 48px;
        height: 48px;
        font-size: 14px;
        padding-left: 4px;
        padding-top: 17px;
    }
  }