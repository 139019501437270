div{
  box-sizing: border-box;
}

a { 
  text-decoration: none; outline: none;
  color: #222222;
}

.header{
  position: fixed;
  width: 100%;
  top: 0;
  background: #ffffff;
  border-bottom: solid 1px #e3e3e3;
}
.content-area::-webkit-scrollbar {
  display: none;
}

.content-area{
  background-color: #FEFEFF;
  height: 100dvh;
  display: flex;
  padding-top: 56px;
  justify-content: center;

  overflow: auto;
}

/* 공통 */

.radius{
  border-radius: 4px;
}

.font-s{
  font-size: 14px;
}

.font-bold{
  font-weight: 500;
}

.font-c-b3{
  color: #b3b3b3;
}

.font-lh-20{
  line-height: 20px;
}

.font-c-green{
  color: #66A500;
}

.auto{
  margin: auto;
}

@media (max-width:1128px){
  body::-webkit-scrollbar {
    display: none;
  }
  }


  @media (max-width:992px){
    .content-area{
      display: flex;
      flex-direction: column;
      padding-top: 76px;
      width: 632px;
      margin: 0px auto;
    }
  }

  @media (max-width:663px){
    .content-area{
      width: 100%;
      padding: 76px 16px 0px 16px;
    }
  }