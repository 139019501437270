$color-black: #222222;
$color-white: #ffffff;
$font-ss: 0.75rem;
$font-s: 0.875rem;
$line-m : 1.5rem;
$skl-color: #b3b3b350;

.skl-grid{
    width: 960px;
    height: 100%;
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

.skl-contents-card {
    display: flex;
    flex-direction: column;
}

.skl-content-img {
    background-color: $skl-color;
    width: 100%;
    height: 184px;
    border-radius: 8px;
    overflow: hidden;
}


.skl-contents-tags {
    margin-top: 10px;
    display: flex;
    font-size: $font-ss;
    font-weight: 600;
    line-height: 1.25rem;
    color: $color-white;
}

.skl-contents-tag {
    width: 56px;
    background-color: $skl-color;
    border-radius: 10px;
    padding: 0 8px 0 8px;
    height: 20px;
}

.skl-contents-title {
    margin-top: 6px;
    width: 100%;
    height: 48px;
    background-color: $skl-color;
    border-radius: 8px;
}

.skl-contents-info {
    margin-top: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.skl-contents-info-pubname {
    background-color: $skl-color;
    width: 80px;
    height: 24px;
    border-radius: 8px;
}

.skl-contents-info-dates {
    width: 92px;
    background-color: $skl-color;
    height: 24px;
    border-radius: 8px;
}