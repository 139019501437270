$color-black: #222222;
$color-white: #ffffff;
$color-gray: #505050;
$font-ss: 0.75rem;
$font-s: 0.875rem;
$line-m : 1.5rem;

.footer{
  width: 100%;
}

.footer-content{
  font-size: $font-ss;
  line-height: 1.25rem;
  color: #50505090;

  text-align: right;
}

